import { generalServices } from "../services/generalServices";
export const SECTORS = "SECTORS";
export const SECTOR_DATA = "SECTOR_DATA";

const getSectorsName = () => dispatch => {
  dispatch({
    type: SECTORS,
    data: JSON.parse(localStorage.getItem("sectorWiseKpi")) || []
  });
};

const getSectorData = (name, year = null) => dispatch => {
  formatData(name, year).then(data => {
    dispatch({
      type: SECTOR_DATA,
      data
    });
  });
};

const formatData = (name, year = null) => {
  return new Promise(async (resolve, reject) => {
    let result = {
      kpis: [],
      averagePerformance: 0
    };

    try {
      let sectors =
        (await JSON.parse(localStorage.getItem("SECTOR_KPIS"))) || [];
      if (Object.keys(sectors).length > 0) {
        let sector = sectors[name];
        let kpis = sector.kpis;

        if (year) {
          kpis = await generalServices.filterByYear(kpis, year);
        }

        result.kpis = (await generalServices.getAveragePerformance(kpis)) || [];
        // result.averagePerformance =
        //   (await generalServices.getGroupAveragePerformance(kpis)) || 0;
      }
    } catch (error) {}

    resolve(result);
  });
};

export const sectorsActions = {
  getSectorsName,
  getSectorData
};
