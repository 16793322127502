import { ALL_KPIS } from "../actions/KPIListActions";

const initState={
  kpis:[],
      raw:{}
}
const kpiListReducer = (state = initState, action) => {
  switch (action.type) {
    case ALL_KPIS:
      return action.data;
    default:
      return state;
  }
};

export default kpiListReducer;