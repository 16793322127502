import { PROGRAM_KPIS, ALL_PROGRAMS } from "../actions/ProgramsActions";

let initialState = {
  name: '',
  kpis: [],
  social: {
    kpis: [],
    sectors: []
  },
  economic: {
    kpis: [],
    sectors: []
  },
  governance: {
    kpis: [],
    sectors: []
  },
  sectors: [],
  averagePerformance:0,
  status:0
};

const programsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAM_KPIS:
			return action.data;
		case ALL_PROGRAMS:
			return action.data
    default:
      return state;
  }
};

export default programsReducer;
