import {
  LOGIN,
  LOGOUT,
  LOADING_DATA,
  AUTH_STATUS
} from "../actions/UsersActions";

let initialState = JSON.parse(localStorage.getItem("logState")) || {
  logState: "IDLE",
  isAuthorized: false,
  currentUser:canViewDashoard()
};
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return action.data;
    case LOADING_DATA:
      return action.data;
    case LOGOUT:
      return action.data;
    case AUTH_STATUS:
      let data = action.data;
      data.currentUser = canViewDashoard();
      return data;
    default:
      return state;
  }
};

function canViewDashoard() {
  const user = JSON.parse(localStorage.getItem("currentUser")) || false;
  let currentUser = {
    canAccessDashboard: false,
    mainPage: "/login"
  };
  if (user) {
    const userType = user.typeOfUser;
    console.log(userType+"|||" )

    if (userType !== "PMO" &&  userType !== "OTP") {
      let page = `/ministries/${user.ministryNames[0]}`;
      currentUser.mainPage = page;
    } else {
      currentUser.canAccessDashboard = true;
    }
  }

  return currentUser;
};

export default usersReducer;
