import { generalServices } from "../services/generalServices";

export const PROGRAM_KPIS = "PROGRAM_KPIS";
export const ALL_PROGRAMS = "ALL_PROGRAMS";

const getAllPrograms = (year = null) => dispatch => {
  allProgramsHelper(year).then(programs => {
    //getting ongoing projects
    let projects = JSON.parse(localStorage.getItem("ONGOINGPROJECTS")) || [];
    let initProjects = {
      totalCount: 0,
      offTrack: {
        count: 0,
        projects: []
      },
      onWatch: {
        count: 0,
        projects: []
      },
      onTrack: {
        count: 0,
        projects: []
      }
    };
    programs.projects = projects || initProjects;
    dispatch({
      type: ALL_PROGRAMS,
      data: programs
    });
  });
};

const getProgramKPIS = (name, year = null) => {
  return dispatch => {
    // getName(name).then(n => {
    getProgramData(name, false, year).then(p => {
      dispatch({
        type: PROGRAM_KPIS,
        data: p
      });
    });
    // });
  };
};

const allProgramsHelper = (year = null) => {
  return new Promise(async resolve => {
    const programNames =
      Object.keys(JSON.parse(localStorage.getItem("PROGRAMS_KPIS"))) || [];

    let programs = {
      programs: [],
      status: 1
    };

    for (const name of programNames) {
      let p = await getProgramData(name, true, year);
      p.shortName=(name.replace(/\d+/g, '')).toLowerCase()
      if (programs.status === 0) {
        if (p.kpis.length > 1) {
          programs.status = 1;
        }
      }
      programs.programs.push(p);
    }
    resolve(programs);
  });
};

const getProgramData = (name, extend = false, year = null) => {
  return new Promise(async resolve => {
    let result = {
      name: name,
      kpis: [],
      social: {
        kpis: [],
        sectors: [],
        color: "yellow"
      },
      economic: {
        kpis: [],
        sectors: [],
        color: "blue"
      },
      governance: {
        kpis: [],
        sectors: [],
        color: "green"
      },
      sectors: [],
      averagePerformance: 0
    };

    try {
      //Getting program data first
      const programs = JSON.parse(localStorage.getItem("PROGRAMS_KPIS"));
      // let count = 5;

      if (Object.keys(programs).length > 0) {
        let program = programs[name];

        if (program) {
          //Adding all program kpis
          let pKpis = program.kpis;

          if (year) {
            pKpis = await generalServices.filterByYear(pKpis, year);
          }

          result.kpis = pKpis;

          if (extend) {
            const clusters = JSON.parse(localStorage.getItem("CLUSTER_KPIS"));
            let socialKPIs = [];
            let governanceKPIs = [];
            let economicKPIs = [];

            if (clusters) {
              const social = clusters.Social ? clusters.Social : {};
              const governance = clusters.Governance ? clusters.Governance : {};
              const economic = clusters.Economic ? clusters.Economic : {};

              socialKPIs = await generalServices.interc(
                pKpis,
                hasData(social) ? social.kpis : null
              );

              if (year) {
                socialKPIs = await generalServices.filterByYear(
                  socialKPIs,
                  year
                );
              }

              result.social.kpis = socialKPIs;

              governanceKPIs = await generalServices.interc(
                pKpis,
                hasData(governance) ? governance.kpis : null
              );

              if (year) {
                governanceKPIs = await generalServices.filterByYear(
                  governanceKPIs,
                  year
                );
              }
              result.governance.kpis = governanceKPIs;

              economicKPIs = await generalServices.interc(
                pKpis,
                hasData(economic) ? economic.kpis : null
              );

              if (year) {
                economicKPIs = await generalServices.filterByYear(
                  economicKPIs,
                  year
                );
              }
              result.economic.kpis = economicKPIs;
            }

            const sectors = JSON.parse(localStorage.getItem("SECTOR_KPIS"));

            if (sectors) {
              const sectorNames = Object.keys(sectors);

              for (const name of sectorNames) {
                let kpis = sectors[name].kpis;

                if (year) {
                  kpis = await generalServices.filterByYear(kpis, year);
                }

                //GETTING KPIS OF A GIVEN SECTOR IN A PROGRAM
                let programSectorKPIs = await generalServices.interc(
                  pKpis,
                  kpis
                );

                if (programSectorKPIs.length > 0) {
                  let sectorKPI = {
                    name: name,
                    kpis: programSectorKPIs
                  };
                  result.sectors.push(sectorKPI);
                }

                //Getting KPIS OF A GIVEN SECTOR IN SOCIAL CLUSTER
                let socialClusterSectorKPIs = await generalServices.interc(
                  socialKPIs,
                  kpis
                );

                if (socialClusterSectorKPIs.length > 0) {
                  let sectorKPI = {
                    name: name,
                    kpis: socialClusterSectorKPIs
                  };
                  result.social.sectors.push(sectorKPI);
                }

                //Getting KPIS OF A GIVEN SECTOR IN GOVERNANCE CLUSTER
                let governanceClusterSectorKPIs = await generalServices.interc(
                  governanceKPIs,
                  kpis
                );

                if (governanceClusterSectorKPIs.length > 0) {
                  let sectorKPI = {
                    name: name,
                    kpis: governanceClusterSectorKPIs
                  };
                  result.governance.sectors.push(sectorKPI);
                }

                let economicClusterSectorKPIs = await generalServices.interc(
                  economicKPIs,
                  kpis
                );

                //Getting KPIS OF A GIVEN SECTOR IN ECONOMIC CLUSTER
                if (economicClusterSectorKPIs.length > 0) {
                  let sectorKPI = {
                    name: name,
                    kpis: economicClusterSectorKPIs
                  };
                  result.economic.sectors.push(sectorKPI);
                }
              }

              //Remove sectors if kpis are empty
              if (result.kpis.length === 0) {
                result.sectors = [];
              }

              if (result.governance.kpis.length === 0) {
                result.governance.sectors = [];
              }

              if (result.economic.kpis.length === 0) {
                result.economic.sectors = [];
              }

              if (result.social.kpis.length === 0) {
                result.social.sectors = [];
              }
            }
          }
        }
      }

      if (year) {
        result.kpis = await generalServices.filterByYear(result.kpis, year);
      }
    } catch (error) {}

    resolve(result);
  });
};

const hasData = kpis => {
  return Object.keys(kpis).length > 0;
};
export const programsActions = {
  getAllPrograms,
  getProgramKPIS
};
