import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Clusters = Loadable({
  loader: () => import("./components/Clusters"),
  loading: Loading
});

export const routes = [
  {
    path: "/cluster/:clusterName",
    exact: true,
    component: Clusters,
    name: "Clusters"
  }
];
