import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { yearSelectorActions } from "../../../actions/YearSelectorActions";
import { programsActions } from "../../../actions/ProgramsActions";
import { ministriesActions } from "../../../actions/MinistriesActions";
import { sectorsActions } from "../../../actions/SectorsActions";
import { clustersActions } from "../../../actions/ClustersActions";

const today = new Date();
const currentYear=today.getFullYear()
class YearSelector extends React.Component {
  constructor(props) {
    super(props);
    let year = localStorage.getItem("year") || 'LATEST';
    this.state = {
      year: year
    };

    this.changeYear = this.changeYear.bind(this);
  }

  render() {
    return (
      <ul className="header-dropdown-list hidden-xs ng-cloak">
        <li className="dropdown">
          <a href="#/" className="dropdown-toggle" data-toggle="dropdown">
            <span>{this.state.year}&nbsp;</span>
            <i className="fa fa-angle-down" />
          </a>
          <ul className="dropdown-menu pull-right">
            {this.getYears().map((year,idx) => {
              return (
                <li onClick={() => this.changeYear(year)} key={idx}>
                  <a href="#">
                    &nbsp;
                    <span>{year}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    );
  }

  getYears = () => {
    let years = ['LATEST'];
    //let start = 2010;
    let start = 2016;

    let end = currentYear;
    for (let i = end; i >= start; i--) {
      years.push(i);
    }
    return years;
  };

  changeYear = year => {
    let selectedYear = year;

    if (year + "" === "LATEST") {
      selectedYear=currentYear;
    }

    localStorage.setItem("year", selectedYear);
    this.props.yearSelectorActions.setYear(selectedYear);

    //Try to check year based on the changing year
    this.props.programsActions.getAllPrograms(selectedYear);
    let selection = this.props.selection;

    //Load program data based on year
    if (selection.program) {
      this.props.programsActions.getProgramKPIS(
        selection.program,
        selectedYear
      );
    }

    //Load sector data based on year
    if (selection.sector) {
      this.props.sectorsActions.getSectorData(selection.sector, selectedYear);
    }

    //Load ministry data based on year
    if (selection.ministry) {
      this.props.ministriesActions.getMinistryKpis(
        selection.ministry,
        selectedYear
      );
    }

    //Load cluster data based on year
    if (selection.cluster) {
      this.props.clustersActions.getClusterData(
        selection.cluster,
        selectedYear
      );
    }
    this.setState({ year: year });

    //window.location.reload();
  };
}

function mapStateToProps(state) {
  return {
    selection: state.selection
  };
}

function mapDispatchToProps(dispatch) {
  return {
    yearSelectorActions: bindActionCreators(yearSelectorActions, dispatch),
    programsActions: bindActionCreators(programsActions, dispatch),
    ministriesActions: bindActionCreators(ministriesActions, dispatch),
    sectorsActions: bindActionCreators(sectorsActions, dispatch),
    clustersActions: bindActionCreators(clustersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(YearSelector);
