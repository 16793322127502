import { axiosService } from "./axiosService";

/**
 * The method to compute the union between two kpis sets
 * The method will return elements of first set (group1) which are also present in the second set (group2)
 *
 * @param  {Array}  group1 First set of kpis
 * @param  {Array}  group2 Second set of kpis
 * @param  {string} name   Optional name to be given the to returned result
 * @return {Array}         Intercection between group1 and group 2 (group1 ∩ group2)
 */
const interc = (group1, group2) => {
  return new Promise((resolve, reject) => {
    let result = [];
    if (group1 && group2) {
      const ids = group2.map(k => k.id);
      result = group1.filter(k1 => ids.includes(k1.id));
    }
    resolve(result);
  });
};

/**
 * For each KPI in the set (array), the method will allow to compute its overall performance
 * At every reporting period, each KPI has three parameters of great importance: Actual, Aggregate and Target.
 * Target is not always given and when it is not there, we rather consider Aggregate.
 * By computing the simple percentage level of perfermance, the overall performance of a given KPI
 * is the average of all computed percentages for a given KPI
 *
 * @param  {Array}  kpis Array of KPIs
 * @return {Array}       KPIs with their overage perfermances
 */
const getAveragePerformance = kpis => {
  return new Promise((resolve, reject) => {
    let result = [];
    try {
      for (const k of kpis) {
        let kData = k;
        let data = k.chartData;
        let oPerf = 0;
        let performances = [];

        //For each KPI loop through all reported periods and for each period compute its percentage of performance
        if (data) {
          for (const c of data) {
            const actual = c.Actual ? c.Actual : c.Aggregate ? c.Aggregate : 0;
            const target = c.Target || 0;
            let performance =
              target > 0 ? Number(((actual * 100) / target).toFixed(1)) : 0;
            performances.push({
              [c.year]: performance
            });
            oPerf += performance || 0;
          }
        }

        kData.performances = performances;

        kData.overallPerformance = data
          ? Number((oPerf / data.length).toFixed(1))
          : 0;
        result.push(kData);
      }

      //Sort KPIs by their performances in ascending order
      result.sort((a, b) =>
        a.overallPerformance > b.overallPerformance ? -1 : 1
      );
      resolve(result);
    } catch (error) {
      console.log("=========");
      console.log(error);
      console.log("=========");
      reject(null);
    }
  });
};

/**
 * The method to compute the overall performance of KPI set
 * In case of sectors, clusters and programs, this method allows to know the overall performance
 * of them
 *
 * @param  {Array}  kpis Array of KPIs
 * @return {Number}      Group overage Performance value
 */
const getGroupAveragePerformance = kpis => {
  return new Promise((resolve, reject) => {
    let result = 0;
    try {
      for (const k of kpis) {
        let data = k.chartData;
        let oPerf = 0;

        for (const c of data) {
          const actual = c.Actual ? c.Actual : c.Aggregate ? c.Aggregate : 0;
          const target = c.Target || 0;
          let performance = Number(((actual * 100) / target).toFixed(1));
          oPerf += performance || 0;
        }

        result +=
          data.length === 0 ? 0 : Number((oPerf / data.length).toFixed(1));
      }

      result = kpis.length === 0 ? 0 : Number(result / kpis.length).toFixed(0);
    } catch (error) {}

    resolve(Number(result));
  });
};

/**
 * The method allow to filter kpis by year
 * The result will be KPIs have data where reporting year is greater or equal to the specified year
 *
 * @param  {Array}  kpis Array of KPIs
 * @param  {Number} kpis Year
 * @return {Array}       Array of KPIs
 */
const filterByYear = (kpis, year) => {
  return new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      //const current = new Date().getFullYear();
      const start = 2013;

      let set = new Set();
      for (const k of kpis) {
        let result = [];
        if (k.chartData) {
          for (const c of k.chartData) {
            //for (let i = Number(year); i <= current; i++) {
            for (let i = start; i <= Number(year); i++) {
              if (c.year.startsWith(i + "")) {
                result.push(c);
              }
            }
          }
        }
        if (result.length > 0) {
          k.chartData = result;
          if (k.name) {
            let add = false;
            if (!set.has(k.name)) {
              set.add(k.name);
              add = true;
            }
            if (add) {
              temp.push(k);
            }
          }
        }
      }
      kpis = temp;
    } catch (error) {}
    resolve(kpis);
  });
};

/**
 * The method which pulls data from backend the user is entitled to
 * After authentication, this method is called and it gets all data the user is entitled to from
 * backend already formatted.
 *
 * @return {Object}  Object
 */
const getFormatedData = () => {
  return new Promise((resolve, reject) => {
    let sessionId = localStorage.getItem("SESSION_COOKIE");

    const resp = {
      userType: "",
      status: 0,
      ministry: ""
    };

    axiosService
      .get("/getAllData/getMinistry", {
        headers: {
          Accept: "application/json",
          SESSION_COOKIE: sessionId
        }
      })
      .then(response => {
        const data = response.data;

        if (data) {
          resp.status = 1;
          const keys = Object.keys(data);

          for (const key of keys) {
            let object = data[key];

            localStorage.setItem(key, JSON.stringify(object));

            if (key === "currentUser") {
              const typeOfUser = object["typeOfUser"];
              resp.userType = typeOfUser;

              if (typeOfUser !== "PMO") {
                resp.ministry = object["ministryNames"][0];
              }
            }
          }
        }

        resolve(resp);
      })
      .catch(e => {
        resp.error = e;
        reject(resp);
      });
  });
};

export const generalServices = {
  interc,
  getGroupAveragePerformance,
  getAveragePerformance,
  filterByYear,
  getFormatedData
};
