import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Program = Loadable({
  loader: () => import("./components/Programs"),
  loading: Loading
});

export const routes = [
  {
    path: "/program/:programName",
    exact: true,
    component: Program,
    name: "NST"
  }
];
