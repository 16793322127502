import { CURRENT_YEAR } from "../actions/YearSelectorActions";

let initState= {
	year: localStorage.getItem("year")||0
};

//year: localStorage.getItem("year")||(new Date()).getFullYear()

const yearSelectorReducer = (state = initState, action) => {
  switch (action.type) {
    case CURRENT_YEAR:
			return action.data;
    default:
      return state;
  }
};

export default yearSelectorReducer;
