import { routes as auth } from "./views/auth";
import { routes as dashboards } from "./views/dashboard";
import { routes as program } from "./views/program";
import { routes as ministries } from "./views/ministries";
import { routes as cluster } from "./views/cluster";
import { routes as sector } from "./views/sector";

export const routes = [
  ...dashboards,
  ...program,
  ...ministries,
  ...cluster,
  ...sector,
];

export const authRoutes = [
  ...auth
];
