import { MINISTRY_KPIS } from "../actions/MinistriesActions";

let initialState = {
  kpis: [],
  groups: [],
  ongoingProjects: []
};

const ministriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MINISTRY_KPIS:
      return action.data;
    default:
      return state;
  }
};

export default ministriesReducer;
