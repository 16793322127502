export const SELECTION_CHANGED = "SELECTION_CHANGED";

const setSelection = (type, value) => dispatch => {
  let selection = JSON.parse(localStorage.getItem("selection")) || {};
	selection[type] = value;
	
	//set selection
	localStorage.setItem("selection",JSON.stringify(selection));

  dispatch({
    type: SELECTION_CHANGED,
    data: selection
  });
};

export const selectionActions = {
	setSelection
};
