export const MENU_DATA = "MENU_DATA";
const getMenuData = () => {
  return dispatch => {
    let result = {
      ministries: {
        title: "Ministries",
        icon: "fa fa-lg fa-fw fa-university txt-color-blue",
        id: "",
        items: []
      },
      clusters: {
        title: "Clusters",
        id: "",
        icon: "fa fa-lg fa-search fa-table",
        items: []
      },
      sectors: {
        title: "Sectors",
        icon: "fa fa-lg fa-signal",
        id: "",
        items: []
      }
    };

    let data = JSON.parse(localStorage.getItem("ministries")) || {};

    if (Object.keys(data).length > 0) {
      let sectors = new Set();
      let clusters = new Set();

      let ministries = Object.keys(data);
      ministries.sort();

      const currentUser=JSON.parse(localStorage.getItem("currentUser"));
      const isPMO=currentUser.typeOfUser==='PMO'?true:false;

      if(isPMO){
        for (const m of ministries){
          setMenuItem(m,result, data, clusters, sectors);
        }
      }
      else{
        const minName=currentUser.ministryNames[0];
        setMenuItem(minName,result, data, clusters, sectors);
      }

      // for (const m of ministries) {
      //   let minName = m.toUpperCase();
      //   let mItem = {
      //     title: minName,
      //     route: `/ministries/${minName}`,
      //     id: getId()
      //   };
      //   if (minName !== "PMO") {
      //     result.ministries.items.push(mItem);
      //     result.ministries.id = getId();
      //   }

      //   //clusters.add(m.clusterName);
      //   let clusterName = data[m].clusterName;

      //   if (!clusters.has(clusterName)) {
      //     clusters.add(clusterName);

      //     if (clusterName !== "PMO") {
      //       let cItem = {
      //         title: clusterName,
      //         route: `/cluster/${clusterName}`,
      //         id: getId()
      //       };
      //       result.clusters.items.push(cItem);
      //       result.clusters.id = getId();
      //     }
      //   }

      //   for (const s of data[m].sectors) {
      //     if (!sectors.has(s)) {
      //       sectors.add(s);
      //       if (s !== "ALL") {
      //         let sItem = {
      //           title: s,
      //           route: `/sector/${s}`,
      //           id: getId()
      //         };
      //         result.sectors.items.push(sItem);
      //         result.sectors.id = getId();
      //       }
      //     }
      //   }
      // }

      
    }

    dispatch({
      type: MENU_DATA,
      data: result
    });
  };
};

const getId = () => {
  return Math.random()
    .toString(36)
    .slice(2);
};

const setMenuItem=(m,result, data, clusters, sectors)=>{
  let minName = m.toUpperCase();
  let mItem = {
    title: minName,
    route: `/ministries/${minName}`,
    id: getId()
  };
  if (minName !== "PMO") {
    result.ministries.items.push(mItem);
    result.ministries.id = getId();
  }

  //clusters.add(m.clusterName);
  let clusterName = data[m].clusterName;

  if (!clusters.has(clusterName)) {
    clusters.add(clusterName);

    if (clusterName !== "PMO") {
      let cItem = {
        title: clusterName,
        route: `/cluster/${clusterName}`,
        id: getId()
      };
      result.clusters.items.push(cItem);
      result.clusters.id = getId();
    }
  }

  for (const s of data[m].sectors) {
    if (!sectors.has(s)) {
      sectors.add(s);
      if (s !== "ALL") {
        let sItem = {
          title: s,
          route: `/sector/${s}`,
          id: getId()
        };
        result.sectors.items.push(sItem);
        result.sectors.id = getId();
      }
    }
  }
}

export const menuActions = {
  getMenuData
};
