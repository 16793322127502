import { SELECTION_CHANGED } from "../actions/SelectionsActions";

let initState= {
	sector: "",
	program: "",
	cluster: "",
	ministry: "",
};

const selectionReducer = (state = initState, action) => {
  switch (action.type) {
    case SELECTION_CHANGED:
			return action.data;
    default:
      return state;
  }
};

export default selectionReducer;