import { axiosService } from "./axiosService";
/**
 * Transforms an array of KPIs into an object of two arrays categories and series
 * Series are performances of the kpis and categories are names of those kpis
 * The method is async which mean that you can make it operate in blocking way (not async) by appending await keywork on its calling
 * @param  {Array}  kpis Array of KPIs
 * @return {Object} Json object similar to the following object {'categories':[],'series':[]}.
 */

const getCategoriesAndSeries = (kpis, addYear = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = {
        categories: [],
        series: [],
        targets: []
      };
      let temp = [];
      let set = new Set();

      for (const k of kpis) {
        if (k.chartData) {
          if (k.chartData.length > 0) {
            const currentData = await getChartData(k.chartData);

            //if (k.overallPerformance > 0) {
            if (k.name) {
              let add = false;
              if (!set.has(k.name)) {
                set.add(k.name);
                add = true;
              }
              if (add) {
                let name = k.name;
                if (addYear) {
                  if (currentData.year) name += ": Year " + currentData.year;
                }

                let obj = {
                  name: name,
                  value: currentData.Actual
                    ? currentData.Actual
                    : currentData.Aggregate
                    ? currentData.Aggregate
                    : 0,
                  target: currentData.Target ? currentData.Target : 0
                };
                temp.push(obj);
              }
            }
            //}
          }
        }
      }
      //Sort the result ascending
      temp.sort((a, b) => (a.value >= b.value ? 1 : -1));

      for (const obj of temp) {
        result.categories.push(obj.name);
        result.series.push(obj.value);
        result.targets.push(obj.target);
      }

      resolve(result);
    } catch (error) {}
  });
};

const getChartData = chartData => {
  try {
    return chartData.slice(-1)[0];
  } catch (error) {
    return {};
  }
};

/**
 * Group KPIs by ministries
 * @param  {Array}  kpis Array of KPIs
 * @return {Object} Json object
 */
const groupKPIs = kpis => {
  let result = {};
  try {
    for (const k of kpis) {
      let id = k.id;
      let name = id.split("_")[0];
      if (!result[name]) {
        result[name] = [];
      }
      result[name].push({
        name: k.name,
        kpi: k
      });
    }
  } catch (error) {}
  return result;
};

const groupByUnitMeasureKPIs = kpis => {
  let temp = {};
  if (kpis) {
    for (const k of kpis) {
      let name = k.unitMeasure;
      name = name.toUpperCase();
      if (!temp[name]) {
        temp[name] = [];
      }
      temp[name].push(k);
    }
  }

  let result = [];
  for (const m of Object.keys(temp)) {
    let obj = { name: m, kpis: temp[m] };
    result.push(obj);
  }

  result.sort((a, b) => (a.kpis.length < b.kpis.length ? 1 : -1));

  return result;
};

const getDrilldownData = (kpi, year, value, key = "key") => {
  return new Promise((resolve, reject) => {
    const sessionId = localStorage.getItem("SESSION_COOKIE");
    const url = `/getDrillDownData/${kpi}/${year}/${key}/${value}`;

    axiosService
      .get(url, {
        headers: {
          Accept: "application/json",
          SESSION_COOKIE: sessionId
        }
      })
      .then(response => {
        const data = response.data;
        const keys = Object.keys(data);
        let result = {
          categories: [],
          series: []
        };

        for (const key of keys) {
          result.categories.push(key);
          result.series.push(data[key]);
        }

        resolve(result);
      })
      .catch(e => {
        console.error(e);
        reject({});
      });
  });
};

const rand = () => {
  return parseInt(Math.random() * 1000);
};

const filterKPIDataByYear = k => {
  try {
    const start = 2013;
    let result = [];
    if (k.chartData) {
      const year = localStorage.getItem("year");
      if (year) {
        for (const c of k.chartData) {
          for (let i = start; i <= Number(year); i++) {
            if (c.year.startsWith(i + "")) {
              result.push(c);
            }
          }
        }
      } else {
        result = k.chartData;
      }
    }

    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const utils = {
  getCategoriesAndSeries,
  groupKPIs,
  groupByUnitMeasureKPIs,
  getDrilldownData,
  rand,
  filterKPIDataByYear
};
