import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { kpiListActions } from "../../actions/KPIListActions";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Dropdown, MenuItem } from "react-bootstrap";

import Modal from "react-bootstrap/lib/Modal";
import { utils } from "../../services/utils";
import request from "then-request";

class KpiDrillDown extends Component {
  constructor(props) {
    super(props);

    const kpiption = {
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: []
      },
      scrollbar: {
        enabled: false
      },
      labels: {
        items: [
          {
            style: {
              left: "50px",
              top: "18px",
              color: (Highcharts.theme && Highcharts.theme.textColor) || "black"
            }
          }
        ]
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function(e) {
                const p = e.point;
                this.doDrillDown(p.category);
              }.bind(this)
            }
          }
        }
      },
      series: [
        {
          type: "column",
          name: "Actual",
          color: "#00A1DE",
          data: []
        },
        {
          type: "spline",
          name: "Target",
          data: [],
          marker: {
            lineWidth: 10,
            lineColor: "#22c75c",
            fillColor: "white"
          }
        }
      ]
    };

    this.state = {
      show: true,
      kpiToShow: kpiption,
      kpId: "",
      dimensions: [],
      dimension: "Period",
      year: 2017,
      targets: {
        type: "spline",
        name: "Target",
        data: [],
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: "white"
        }
      }
    };

    this.setDimension = this.setDimension.bind(this);
    this.doDrillDown = this.doDrillDown.bind(this);
  }

  closeModal = () => {
    this.setState({ show: false, text: "" });
    window.location.reload();
  };

  getKPI = () => {
    let kpis = this.props.kpis.raw;
    const name = this.props.name.split("_")[0];
    this.setState({
      text: name,
      show: true,
      suggestions: []
    });
    this.getKPIData(kpis[name]);
  };

  getKPIData = kpi => {
    let result = {
      categories: [],
      actuals: [],
      targets: []
    };

    if (kpi) {
      let data = kpi.chartData;
      let name =
        kpi.name.indexOf("(") < 0
          ? `${kpi.name} (${kpi.unitMeasure})`
          : kpi.name;
      if (data) {
        for (const d of data) {
          result.categories.push(d.year);
          result.actuals.push(
            d.Actual ? d.Actual : d.Aggregate ? d.Aggregate : 0
          );
          result.targets.push(d.Target || 0);
        }
      }

      let kpiState = this.state.kpiToShow;
      kpiState.title.text = name;

      kpiState.xAxis.categories = result.categories;
      if (kpiState.series.length === 1) {
        kpiState.series.push(this.state.targets);
      }
      kpiState.series[0].data = result.actuals;
      kpiState.series[1].data = result.targets;
      this.setState({
        kpiToShow: kpiState,
        show: true,
        kpId: kpi.id,
        currentKPIName: "KPI PERFORMANCE"
      });

      this.setDimensions(kpi.dimensionList);
    }
  };

  setDimensions = dimensions => {
    if (dimensions) {
      dimensions = dimensions.filter(d => d.key !== "period");
      if (dimensions.length > 0) {
        this.setState({
          dimension: dimensions[0].value,
          dimensions
        });
      }
    }
  };

  setDimension = dimension => {
    this.setState({ dimension });
    let year = this.state.year;
    if (year) {
      this.doDrillDown(year, dimension);
    }
  };

  doDrillDown = (value, dimension = null) => {
    let year = new Date().getFullYear() + "";
    if (this.state.dimension) {
      if (!isNaN(value)) {
        this.setState({ year: value });
        year = value;
      } else {
        year = this.state.year;
      }

      if (dimension == null) {
        dimension = this.state.dimension;
      }

      console.log(this.state.kpId, year, dimension);

      utils.getDrilldownData(this.state.kpId, year, dimension).then(data => {
        if (data) {
          let kpiState = this.state.kpiToShow;

          if (kpiState.series.length > 1) {
            kpiState.series.pop();
          }

          kpiState.xAxis.categories = data.categories;
          kpiState.series[0].data = data.series;
          this.setState({
            kpiToShow: kpiState
          });
        }
      });
    }
  };

  componentDidMount() {
    this.getKPI();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.name !== this.props.name) {
      this.getKPI();
      this.setState({ show: true });
    }
  }

  componentWillMount() {
    request("GET", "assets/api/graphs/morris.json", { json: true }).done(
      res => {
        this.setState(JSON.parse(res.getBody()));
      }
    );
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.closeModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h3 className="text-center">
                <u>{this.state.currentKPIName}</u>
              </h3>
            </Modal.Title>

            <div className="widget-toolbar">
              {this.state.dimensions.length > 0 && (
                <Dropdown className="btn-group" id="widget-demo-dropdown">
                  <Dropdown.Toggle className="btn btn-xs dropdown-toggle">
                    Filter by:
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu pull-right">
                    {this.state.dimensions.map(d => {
                      return (
                        <MenuItem onClick={() => this.setDimension(d.key)}>
                          {d.value}
                        </MenuItem>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </Modal.Header>
          <Modal.Body>
            <HighchartsReact
              highcharts={Highcharts}
              options={this.state.kpiToShow}
              allowChartUpdate={true}
              immutable={false}
              updateArgs={[true, true, true]}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    kpis: state.kpiListReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    kpiListActions: bindActionCreators(kpiListActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KpiDrillDown);
