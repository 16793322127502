import React from "react";
export default class Footer extends React.Component {
  render() {
    return (
      <div className="page-footer">
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <div className="text-center lead">
              <span className="txt-color-white">
                Government Command Center(GCC) © {(new Date()).getFullYear()}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
