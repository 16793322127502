export const CURRENT_YEAR = "CURRENT_YEAR";

const setYear = (year) => dispatch => {
  localStorage.setItem("year",year);
  dispatch({
    type: CURRENT_YEAR,
    data: {year:year}
  });
};

export const yearSelectorActions = {
  setYear
};