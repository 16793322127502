import axios from "axios";
const baseURL = "https://gcc.gov.rw/gcc";
const axiosOptions = {
  baseURL: `${baseURL}/api/`,
  routes: {
    cors: {
      origin: ["*"]
    }
  }
};

export const axiosService = axios.create(axiosOptions);

axiosService.interceptors.request.use(
  config => {
    const token = localStorage.getItem("SESSION_COOKIE");

    if (token) {
      config.headers.common["SESSION_COOKIE"] = token;
      config.headers.common["Accept"] = "application/json";
    }
    return config;
  },
  err => Promise.reject(err)
);
