import { CLUSTER_DATA } from "../actions/ClustersActions";

let initState= {
	kpis: [],
	averagePerformance: 0
};

const clustersReducer = (state = initState, action) => {
  switch (action.type) {
    case CLUSTER_DATA:
			return action.data;
    default:
      return state;
  }
};

export default clustersReducer;
