import React from "react";
import { BigBreadcrumbs } from "../../../common/navigation";


export default class Ribbon extends React.Component {
  render() {
    return (
      <div id="ribbon">
        <span className="btn-ribbon">
          <BigBreadcrumbs
            items={["GCC", "HOME"]}
            className="col-xs-12 col-sm-7 col-md-7 col-lg-4"
          />
        </span>
      </div>
    );
  }
}
