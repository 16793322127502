import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { Shortcut, Navigation } from "../../navigation";

import Header from "./Header";
import Footer from "./Footer";

import { routes } from "../../../routes";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../../../actions/UsersActions";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: true
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isAuthorized ? (
          <div>
            <Header />
            <Navigation />
            <div id="main" role="main">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/misc/404" />
              </Switch>
            </div>

            <Footer />
            <Shortcut />
          </div>
        ) : (
          <Redirect from="/" to="/login" />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthorized: state.users.isAuthorized
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
