import React from "react";
import { Msg } from "../../i18n";

export default class RecentProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        {
          href: "/",
          title:
            "Online e-merchant management system - attaching integration with the iOS"
        },
        {
          href: "/",
          title: "Notes on pipeline upgradee"
        },
        {
          href: "/",
          title: "Assesment Report for merchant account"
        }
      ]
    };
  }
  clearProjects = () => {
    this.setState({
      projects: []
    });
  };
  render() {
    return (
      <div className="project-context hidden-xs dropdown">
        <div className="outer_div">
        <div className="inner_div">
          <span
            className="project-selector"
          >
            <Msg phrase="GOVERNMENT COMMAND CENTER" />
          </span>
        </div>
        </div>
      </div>
    );
  }
}
