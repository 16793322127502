import { authService } from "../services/authService";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOADING_DATA = "LOADING_DATA";
export const AUTH_STATUS = "AUTH_STATUS";

const dispatchHelper = (type, data) => {
  return { type: type, data };
};

const login = (username, password) => dispatch => {
  dispatch(
    dispatchHelper(LOGIN, {
      logState: "AUTHENTICATING",
      isAuthorized: false
    })
  );

  authService.login(username, password).then(response => {
    if (response.status === 200) {
      dispatch(
        dispatchHelper(LOGIN, {
          logState: "SUCCESS",
          isAuthorized: true
        })
      );
    } else {
      if (response.status === 403) {
        dispatch(
          dispatchHelper(LOGIN, {
            logState: "BAD_CREDENTIALS",
            isAuthorized: false
          })
        );
      } else {
        dispatch(
          dispatchHelper(LOGIN, {
            logState: "NETWORK_ERROR",
            isAuthorized: false
          })
        );
      }
    }
  });
};

const logout = () => dispatch=>{
  authService.logout();
  const data = { isAuthorized: false, logState: "LOGOUT" };
  localStorage.setItem("logState", JSON.stringify(data));
  return dispatch({ type: LOGOUT, data });
};

const changeStatus = (auth, status) => dispatch=>{
 
  //Set the current status in the storage
  const data = { isAuthorized: auth, logState: status };

  //Set the current status in the storage
  localStorage.setItem("logState", JSON.stringify(data));
  return dispatch({ type: AUTH_STATUS, data });
};



export const authActions = {
  login,
  logout,
  changeStatus
};
