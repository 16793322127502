import React from "react";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { config } from "../../../config/config";
import * as LayoutActions from "../LayoutActions";

class LayoutSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivated: false
    };
  }

  onToggle = () => {
    this.setState({
      isActivated: !this.state.isActivated
    });
  };

  render() {
    return (
      <div
        className={classnames("demo", {
          activate: this.state.isActivated
        })}
      >
        <span id="demo-setting" onClick={this.onToggle}>
          <i className="fa fa-cog txt-color-blueDark" />
        </span>
        <form>
          <legend className="no-padding margin-bottom-10">
            Layout Options
          </legend>
          <section>
            <label>
              <input
                type="checkbox"
                checked={this.props.largeFont}
                onChange={this.props.onLargeFont}
                className="checkbox style-0"
              />
              <span>Large Font</span>
            </label>
            <h6 className="margin-top-10 semi-bold margin-bottom-5">
              Clear personal customization
            </h6>
            <a href="#/"
              onClick={this.props.factoryReset}
              className="btn btn-xs btn-block btn-primary"
              id="reset-smart-widget"
            >
              <i className="fa fa-refresh" /> Restore defaults
            </a>
          </section>

          <h6 className="margin-top-10 semi-bold margin-bottom-5">
            Available layouts
          </h6>

          <section id="smart-styles">
            {config.skins.map(skin => {
              const check =
                this.props.smartSkin === skin.name ? (
                  <i className="fa fa-check fa-fw" />
                ) : (
                    ""
                  );
              const beta = skin.beta ? <sup>beta</sup> : "";
              return (
                <a href="#/"
                  onClick={this.props.onSmartSkin.bind(this, skin)}
                  className={skin.class}
                  style={skin.style}
                  key={skin.name}
                >
                  {check} {skin.label} {beta}
                </a>
              );
            })}
          </section>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => state.layout;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(LayoutActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutSwitcher);
