import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Minisitry = Loadable({
  loader: () => import("./components/Ministry"),
  loading: Loading
});


export const routes = [
  {
    path: "/ministries/:ministryName",
    exact: true,
    component: Minisitry,
    name: "Ministiry"
  }
];
