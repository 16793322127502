import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Sectors = Loadable({
  loader: () => import("./components/Sectors"),
  loading: Loading
});

export const routes = [
  {
    path: "/sector/:sectorName",
    exact: true,
    component: Sectors,
    name: "Sectors"
  }
];
