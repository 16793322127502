import React, { Component } from "react";
import "./autocomplete.css";
import KpiDrillDown from '../drillDownModal'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { kpiListActions } from "../../actions/KPIListActions";

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      suggestions: [],
      text: "",
      show: false,
      currentKPIName: "",
    };
  }

  componentWillMount() {
    this.props.kpiListActions.getAllKPIs();
  }

  onTextChanged = e => {
    if (this.state.items) {
      this.setState({ items: this.props.kpis.kpis });
    }

    const value = e.target.value;
    let suggestions = [];

    if (value.length > 2) {
      let items = this.state.items;
      const regex = new RegExp(`${value}`, "i");
      suggestions = items.sort().filter(w => regex.test(w));
    }

    this.setState({ suggestions, text: value });
  };

  suggestionSelected = value => {
    this.setState({
      text: "",
      suggestions: [],
      currentKPIName:value, 
      show:true
    });
  };

  renderSuggestion = () => {
    const suggestions = this.state.suggestions;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="ui-menu ui-widget ui-widget-content no_width">
        {suggestions.map(e => {
          return (
            <li
              onClick={() => this.suggestionSelected(e)}
              style={{ cursor: "pointer" }}
            >
              {e}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    return (
      <div>
        <span className="select2-container" dir="ltr"></span>{" "}
        {/* Hack for the list to appear below the search box*/}
        <div className="header-search pull-right">
          <input
            autoComplete="off"
            id="search-fld"
            type="text"
            name="param"
            placeholder="Search for KPI"
            onChange={this.onTextChanged}
            value={this.state.text}
          />
          <button type="button">
            <i className="fa fa-search" />
          </button>
          <a href="$" id="cancel-search-js" title="Cancel Search">
            <i className="fa fa-times" />
          </a>
        </div>
        {this.renderSuggestion()}
        {/*Modal will come here */}
        {this.state.show && (
          <KpiDrillDown name={this.state.currentKPIName}/>
        )}
        {/*end */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    kpis: state.kpiListReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    kpiListActions: bindActionCreators(kpiListActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Autocomplete);
