import React from "react";

import FullScreen from "./FullScreen";
import ToggleMenu from "./ToggleMenu";
import SearchMobile from "./SearchMobile";
import LayoutSwitcher from "./LayoutSwitcher";

import YearSelector from "../../../common/reusable/components/YearSelector";
import RecentProjects from "./RecentProjects";
import Autocomplete from "../../../common/autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../../../actions/UsersActions";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.signout = this.signout.bind(this);
  }
  signout = () => {
    this.props.authActions.logout();
  };

  render() {
    return (
      <header id="header">
        <div id="logo-group">
          <span id="logo">
            <img
              src="assets/img/logo.png" // place your logo here
              alt="Logo"
            />
          </span>
          {/* Note: The activity badge color changes when clicked and resets the number to 0
         Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications */}
        </div>

        <RecentProjects />
        <div className="pull-right" /*pulled right: nav area*/>
          <ToggleMenu
            className="btn-header pull-right" /* collapse menu button */
          />
          {/* #MOBILE */}
          {/*  Top menu profile link : this shows only when top menu is active */}
          <ul
            id="mobile-profile-img"
            className="header-dropdown-list hidden-xs padding-5"
          >
            <li className="">
              <a
                href="#/"
                className="dropdown-toggle no-margin userdropdown"
                data-toggle="dropdown"
              >
                <img
                  src="assets/img/avatars/sunny.png"
                  alt="John Doe 1"
                  className="online"
                />
              </a>
              <ul className="dropdown-menu pull-right">
                <li>
                  <a
                    href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                  >
                    <i className="fa fa-cog" /> Setting
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/views/profile"
                    className="padding-10 padding-top-0 padding-bottom-0"
                  >
                    <i className="fa fa-user" />
                    <u>P</u>rofile
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="toggleShortcut"
                  >
                    <i className="fa fa-arrow-down" /> <u>S</u>hortcut
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="launchFullscreen"
                  >
                    <i className="fa fa-arrows-alt" /> Full
                    <u>S</u>creen
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/login"
                    className="padding-10 padding-top-5 padding-bottom-5"
                    data-action="userLogout"
                  >
                    <i className="fa fa-sign-out fa-lg" />
                    <strong>
                      <u>L</u>ogout
                    </strong>
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          {/* logout button */}
          <LayoutSwitcher />

          {/* logout button */}
          <div
            id="logout"
            className="btn-header transparent pull-right before_last"
          >
            <span>
              <a
                onClick={() => this.signout()}
                title="Sign Out"
                data-logout-msg="You can improve your security further after logging out by closing this opened browser"
              >
                <i className="fa fa-sign-out" />
              </a>
            </span>
          </div>

          {/* search mobile button (this is hidden till mobile view port) */}
          <SearchMobile className="btn-header transparent pull-right" />

          {/* input: search field */}
          <div className="header-search pull-right">
            <Autocomplete />
          </div>

          <div className="header-search pull-right" />
          <FullScreen className="btn-header transparent pull-right" />
          <YearSelector />
        </div>
        {/* end pulled right: nav area */}
      </header>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(Header);
