import { SELECTED_KPI } from "../actions/KPIActions";

const kpiReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_KPI:
      return action.data;
    default:
      return state;
  }
};

export default kpiReducer;