import { generalServices } from "../services/generalServices";
export const MINISTRY_KPIS = "MINISTRY_KPIS";

const getMinistryKpis = (name, year = null) => dispatch => {
  getMinistryData(name, year).then(data => {
    dispatch({
      type: MINISTRY_KPIS,
      data
    });
  });
};

const getMinistryData = (name, year = null) => {
  return new Promise(async (resolve, reject) => {
    let kpis =
      (await generalServices.getAveragePerformance(
        JSON.parse(localStorage.getItem(`${name}_KPIS`))
      )) || [];

    let groups = JSON.parse(localStorage.getItem(`${name}_PROGRAMS`)) || [];

    if (year>0) {
      if (kpis.length > 0) {
        console.log("*****")
        console.log("All KPIS", kpis)
        console.log("*****")
        kpis = await generalServices.filterByYear(kpis, year);
        console.log("*****")
        console.log("Filter KPIS", kpis)
        console.log("*****")
        //Loop through all groups and filter kpis
        let temp = [];
        for (const g of groups) {
          let gKpis = await generalServices.filterByYear(g.kpis, year);

          if (gKpis.length > 0) {
            g.kpis = gKpis;
            temp.push(g);
          }
        }
        groups = temp;
      }
    }

    let result = {
      kpis: kpis,
      groups: groups,
      projects:
        JSON.parse(localStorage.getItem(`${name}_OngoingProjects`)) || []
      //   ,
      // averagePerformance:
      //   (await generalServices.getGroupAveragePerformance(kpis)) || 0
    };

    resolve(result);
  });
};

export const ministriesActions = {
  getMinistryKpis
};
