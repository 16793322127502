export const ALL_KPIS = "ALL_KPIS";

const getAllKPIs =  () => dispatch => {
  const kpis=localStorage.getItem("ALL_KPIS")||"{}";

  const raw= JSON.parse(kpis);

  const result={
    kpis:Object.keys(raw),
    raw:raw
  }

  dispatch({
    type: ALL_KPIS,
    data: result
  });
};

export const kpiListActions = {
  getAllKPIs
};