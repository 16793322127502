import { SECTORS,SECTOR_DATA } from "../actions/SectorsActions";


const sectorsReducer = (state = [], action) => {
  switch (action.type) {
    case SECTORS:
			return action.data;
    case SECTOR_DATA:
			return action.data;
    default:
      return state;
  }
};

export default sectorsReducer;
