import { axiosService } from "./axiosService";
import querystring from "query-string";

/**
 * Authenticate the user having his/her username and password
 * The method is async which mean that you can make it operate in blocking way (not async) by appending await keywork on its calling
 * @param  {String} username username of the user
 * @param  {String} password password of the user
 * @return {Object}          Json object similar to the following object {'stat_code':200,'SESSION_COOKIE':'JSESSIONID=1C02B2656CDDC038248B2989065ABDA6'}. When the stat_code is 200, the user is successfully authenticated, otherwise he/she is not.
 */
 function login(username, password) {
  const credentials = querystring.stringify({
    j_username: username,
    j_password: password
  });
  return new Promise((resolve, reject) => {
    axiosService
      .post("/login/j_spring_security_check", credentials, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json"
        }
      })
      .then(response => {
        if (response.status === 200) {
        }
        resolve(response.data);
      })
      .catch(e => {
        console.error(e);
        reject({ error: 401, text: e });
      });
  });
}

/**
 * Logout method
 * This method is very important because it allows to invalidate the user's ongoing session.
 * Note that the user is only allowed to create 15 session and failing to call this method will make login not work when the user would
 * have exhausted 15 session resulting from logging in without logging out.
 */
function logout() {
  const sessionId = localStorage.getItem("SESSION_COOKIE");
  localStorage.clear();

  // format the object
  const sessionCookie = querystring.stringify({
    SESSION_COOKIE: sessionId
  });
  axiosService
    .post("/logout", sessionCookie, {
      headers: {
        Accept: "application/json",
        SESSION_COOKIE: sessionId
      }
    })
    .then(resp => {
      console.log(resp.data);
    });
}

export const authService = {
  login,
  logout
};
