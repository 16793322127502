import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import {
  handleBodyClasses,
  dumpLayoutToStorage,
  layoutReducer
} from "../common/layout";

import { navigationReducer } from "../common/navigation";
import { eventsReducer } from "../common/calendar";
import { i18nReducer, i18nInit } from "../common/i18n";
import ministriesReducer from '../reducers/MinistriesReducer';
import programsReducer from '../reducers/ProgramsReducer';
import sectorsReducer from '../reducers/SectorsReducer';
import clustersReducer from '../reducers/ClustersReducer';
import usersReducer from '../reducers/UsersReducer';
import menuReducer from '../reducers/MenuReducer';
import yearSelectorReducer from '../reducers/YearSelectorReducer';
import selectionReducer from '../reducers/SelectionReducer';
import kpiReducer from '../reducers/KPIReducer';
import kpiListReducer from '../reducers/KPIListReducer';

import {
  VoiceMiddleware,
} from "../common/voice-control";

export const rootReducer = combineReducers({
  layout: layoutReducer,
  navigation: navigationReducer,
  events: eventsReducer,
  i18n: i18nReducer,
  //Added by Jean Pierre
  ministries:ministriesReducer,
  programs:programsReducer,
  sectors:sectorsReducer,
  users:usersReducer,
  clusters:clustersReducer,
  years:yearSelectorReducer,
  menuReducer,
  selection:selectionReducer,
  kpiReducer,
  kpiListReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      handleBodyClasses,
      dumpLayoutToStorage,
      VoiceMiddleware
    )
  )
);

// store.dispatch(chatInit());
// store.dispatch(requestUserInfo());

store.dispatch(i18nInit());



// if (config.voice_command_auto) {
//   store.dispatch(voiceControlOn());
// }

export default store;
