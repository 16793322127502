import { MENU_DATA } from "../actions/MenuActions";

let initialState ={
	ministries: {
		title: "Ministries",
		icon: "fa fa-lg fa-fw fa-university txt-color-blue",
		id:'',
		items: []
	},
	clusters: {
		title: "Clusters",
		id:'',
		icon: "fa fa-lg fa-search fa-table",
		items: []
	},
	sectors: {
		title: "Sectors",
		icon: "fa fa-lg fa-signal",
		id:'',
		items: []
	}
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_DATA:
      return action.data;
    default:
      return state;
  }
};

export default menuReducer;