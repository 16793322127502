export const SELECTED_KPI = "SELECTED_KPI";

const getSelectedKPI = (name) => dispatch => {
  const kpis=JSON.parse(localStorage.getItem("ALL_KPIS"))||{}
  
	const kpi =kpis[name]||{};
	
  dispatch({
    type: SELECTED_KPI,
    data: kpi
  });
};

export const kpiActions = {
  getSelectedKPI
};