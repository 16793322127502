import { generalServices } from "../services/generalServices";
export const CLUSTER_DATA = "CLUSTER_DATA";

const getClusterData = (name, year) => dispatch => {
  formatData(name, year).then(data => {
    dispatch({
      type: CLUSTER_DATA,
      data
    });
  });
};

const formatData = (name, year = null) => {
  return new Promise(async (resolve, reject) => {
    let result = {
      kpis: [],
      averagePerformance: 0
    };
    try {
      let clusters = JSON.parse(localStorage.getItem("CLUSTER_KPIS")) || [];
      if (Object.keys(clusters).length > 0) {
        let cluster = clusters[name];
        let kpis = cluster.kpis;
        if (year) {
          kpis = await generalServices.filterByYear(kpis, year);
        }
        result.kpis = (await generalServices.getAveragePerformance(kpis)) || [];
        // result.averagePerformance =
        //   (await generalServices.getGroupAveragePerformance(kpis)) || 0;
      }
    } catch (error) {}
    resolve(result);
  });
};

export const clustersActions = {
  getClusterData
};
